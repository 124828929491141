/**
 * Scroll to an element using a useRef or HTML DOM object.
 * @param {object} object - HTML DOM object.
 * @param {object} options - Object containing additional options.
 */
export const objectScrollTo = (object, options = {}) => {
    if (!object || !object.scrollIntoView) return;

    object.scrollIntoView({ behavior: 'smooth', block: 'start', ...options });
};

/**
 * Scroll to an element using a css selector such as a class or id.
 * @param {string} selector - css selector to query the DOM for.
 * @param {object} options - Object containing additional options.
 */
export const selectorScrollTo = (selector = '', options = {}) => {
    const element = document.querySelector(selector);

    if (!element || !element.scrollIntoView) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'start', ...options });
};

/**
 * Scrolls user to the top of the page.
 */
export const scrollToTop = () => {
    if (typeof window !== 'undefined') {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
};
