'use client';

import React from 'react';

import { HeaderFields } from '../../../Header/Header';
import useCloseMegaMenuOnNavigation from './hooks/useCloseMegaMenuOnNavigation';

import MegamenuTreatments from './MegamenuTreatments';
import MegamenuConditions from './MegamenuConditions';
import MegamenuBrands from './MegamenuBrands';

type ComponentList = {
    [key: string]: React.ComponentType<any> | null;
};

const componentList: ComponentList = {
    treatments: MegamenuTreatments,
    conditions: MegamenuConditions,
    brands: MegamenuBrands,
};

interface Props {
    navItem: NonNullable<HeaderFields['mainNavigation']>['items'][number];
    treatmentCount: number;
    conditionCount: number;
    conditions?: {
        id: string;
    }[];
}

export const getTypeOfMegamenuFromClasses = (classes: Props['navItem']['classes']) => (
    (classes || '').replace('_has-megamenu--', '')
)

const MegaMenu = ({ navItem, treatmentCount, conditionCount, conditions = [] }: Props) => {
    const type = getTypeOfMegamenuFromClasses(navItem.classes);

    useCloseMegaMenuOnNavigation();

    const ComponentToRender = componentList[type] || null;

    if (ComponentToRender === null) {
        return null;
    }

    return (
        <ComponentToRender
            navItems={navItem.children}
            conditions={conditions}
            treatmentCount={treatmentCount}
            conditionCount={conditionCount}
        />
    );
};

export default MegaMenu;
