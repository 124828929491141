'use client';

import React from 'react';

import { useSearchContext } from '../../../../data/context/searchContext';

import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../../_ui/_blocks/Icon/Icon';

import styles from '../Header.module.css';

const SearchButton = () => {
    const { setShowOverlay } = useSearchContext();
    const handleOpenOverlay = () => {
        setShowOverlay(true);
    }

    return (
        <Button
            variant="none"
            aria-label="Open the search"
            title="Open the search"
            onClick={handleOpenOverlay}
            className={`${styles.link} lg:hidden _js-draw-nav-search`}
        >
            <Icon icon="search-dark" size="medium" alt="Search" loading="eager" />
        </Button>
    );
};

export default SearchButton;
