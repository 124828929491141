"use client"

/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState, useMemo } from 'react';
import { useNavigationCloseHook } from '../../../hooks/useNavigationCloseHook';

/** Start of the basket context. */
const AccountNavigationContext = createContext();

/**
 * This provider handles all of our search state. Wrapping a new provider will create a new scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for inpage results vs overlay results.
 * @param {*} props
 */
export const AccountNavigationProvider = ({ ...props }) => {
    const [accountNavIsOpen, setAccountNavIsOpen] = useState(false);

    useNavigationCloseHook('account', () => setAccountNavIsOpen(false));

    const value = useMemo(
        () => ({
            accountNavIsOpen,
            setAccountNavIsOpen,
        }),
        [accountNavIsOpen, setAccountNavIsOpen]
    );

    return <AccountNavigationContext.Provider {...props} value={value} />;
};

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider above.
 */
export const useAccountNavigationContext = () => {
    const context = useContext(AccountNavigationContext);

    if (context === undefined) {
        throw new Error('useAccountNavigationContext must be used within a AccountNavigationProvider');
    }

    return context;
};
