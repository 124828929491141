'use client';

import React from 'react';

import Typography from '../../../../_ui/_blocks/Typography/Typography';
import Link from '../../../../NextJs/Link/Link';
import Icon from '../../../../_ui/_blocks/Icon/Icon';

import styles from './AccountLink.module.css';

interface Props {
    children: React.ReactNode;
    href: string;
}

const AccountLink = ({ href, children }: Props) => (
    <Link
        href={href}
        aria-label="Your Independent Pharmacy account"
        title="Your Independent Pharmacy account"
        className={styles.link}
    >
        <Typography size="100" color="white" weight="700">{children}</Typography>
        <Icon icon="profile-dark" size="medium" alt="Profile" loading="eager" />
    </Link>
);

export default AccountLink;
