'use client';

import React from 'react';

import { useSidebarNavigationUpdaterContext } from '../../../../data/context/sidebarNavigationContext';

import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import Icon from '../../../_ui/_blocks/Icon/Icon';

import styles from '../Header.module.css';

const BurgerMenuButton = () => {
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();
    const handleSidebarOpen = () => setSidebarNavIsOpen((s: boolean) => !s);

    return (
        <Button
            variant="none"
            aria-label="Open the main menu"
            title="Open the main menu"
            onClick={handleSidebarOpen}
            className={`flex items-center ${styles.link}`}
        >
            <Typography weight="700" size="100" color="white" className="hidden lg:block">
                Menu
            </Typography>
            <Icon icon="menu-dark" size="medium" alt="Menu" loading="eager" />
        </Button>
    );
};

export default BurgerMenuButton;
