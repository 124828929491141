'use client';

import React, { useMemo, useRef } from 'react';
import { Transition } from '@headlessui/react';

import { isBrowser } from '../../../../config/config';
import useSiteBanner from '../../hooks/useSiteBanner';

import Button from '../../../../components/_ui/_blocks/Buttons/Button/Button';
import Icon from '../../../../components/_ui/_blocks/Icon/Icon';

import styles from './SiteBanner.module.css';

const SiteBanner = () => {
    const { siteBanners, markBannerAsSeen } = useSiteBanner();

    const firstSiteBanner = useMemo(() => (siteBanners.length ? siteBanners[0] : null), [siteBanners]);

    // Makes sure the last piece of text is kept when the banner is closed for smoother animation.
    const previousText = useRef('');

    if (!isBrowser()) {
        return null;
    }

    const handleClose = () => {
        previousText.current = firstSiteBanner?.body || '';
        markBannerAsSeen(firstSiteBanner?.uuid || '');
    };

    return (
        <Transition
            show={!!firstSiteBanner}
            className={styles.banner}
            appear
            enterTo={styles.bannerAnimateIn}
            leaveFrom={styles.bannerAnimateIn}
            unmount
        >
            {/* Data attribute used in global.css. */}
            <div className={styles.content} data-site-banner="true">
                <div
                    className={styles.wysiwyg}
                    dangerouslySetInnerHTML={{
                        __html: firstSiteBanner?.body || previousText.current,
                    }}
                />
                <Button
                    type="button"
                    variant="none"
                    size="none"
                    title="Close message"
                    onClick={firstSiteBanner?.uuid ? handleClose : undefined}
                >
                    <Icon icon="cross-light" alt="Close" />
                </Button>
            </div>
        </Transition>
    );
};

export default SiteBanner;
