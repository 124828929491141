import React from 'react';

import { useAccountNavigationContext } from '../../data/context/accountContext/accountNavigationContext';
import { useSidebarNavigationUpdaterContext } from '../../data/context/sidebarNavigationContext';
import useSidebarScrollToHash from './CraftMenuTypes/SidebarNavigation/hooks/useSidebarScrollToHash';

import Flyout from '../_ui/Flyout/Flyout';
import AccountMenu from './AccountNavigation/AccountMenu/AccountMenu';

const AccountNavigation = () => {
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();
    const { accountNavIsOpen, setAccountNavIsOpen } = useAccountNavigationContext();

    const handleBackToMenu = () => {
        setSidebarNavIsOpen(true);
        setAccountNavIsOpen(false);
    };

    const handleCloseNavigation = () => {
        setAccountNavIsOpen(false);
    };

    const scrollToHashOnClose = useSidebarScrollToHash(accountNavIsOpen);

    return (
        <Flyout
            isOpen={accountNavIsOpen}
            onBack={handleBackToMenu}
            onClose={handleCloseNavigation}
            title="Back to menu"
            afterLeave={scrollToHashOnClose}
        >
            <AccountMenu />
        </Flyout>
    );
};

export default AccountNavigation;
