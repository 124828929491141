'use client';

import { useMemo, useState } from 'react';
import useSWR from 'swr';

import siteBannerService from '../../../data/services/siteBanner.service';
import { localStorageGet, localStorageSet } from '../../../helpers/localStorage';

export const localStorageBannerKey = 'seen_banners';

/**
 * This hook is used to fetch the site banners from the API and filter out the ones that don't need to be
 * shown.
 */
const useSiteBanner = () => {
    const [seenBanners, setSeenBanners] = useState<string[]>(localStorageGet(localStorageBannerKey) || []);

    // Gets the banners from the API.
    const { data: axiosResponse } = useSWR('siteBanner', siteBannerService.getSiteBanner, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    // Mark a banner as seen and stores it in local storage.
    const markBannerAsSeen = (bannerId: string | number) => {
        const currentSeenBanners = localStorageGet(localStorageBannerKey) || [];
        currentSeenBanners.push(bannerId.toString());

        localStorageSet(localStorageBannerKey, currentSeenBanners);
        setSeenBanners(currentSeenBanners);
    };

    // Filters the API banners that need to be shown.
    const unSeenBanners = useMemo(() => {
        const banners = axiosResponse?.data?.data || [];

        return banners.filter(({ uuid }) => {
            // If the banner has already been seen, don't show it.
            if (seenBanners.includes(uuid.toString())) {
                return false;
            }

            return true;
        });
    }, [axiosResponse, seenBanners]);

    return {
        siteBanners: unSeenBanners,
        markBannerAsSeen,
    };
};

export default useSiteBanner;
