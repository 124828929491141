"use client";

import React from 'react';
import clx from 'classnames';

import { useAuthContext } from '@/data/context/authContext';

import Button from '@/components/_ui/_blocks/Buttons/Button/Button';

import styles from '../../CraftMenuTypes/SidebarNavigation/SidebarNavigation.module.css';
import LinkActiveClassName from '../../../LinkActiveClassName';
import Icon from '../../../_ui/_blocks/Icon/Icon';

interface Props {
    className?: string;
}

const AccountMenu = ({ className }: Props) => {

    const { logout } = useAuthContext();

    const handleLogout = () => logout();

    const navClassName = clx(styles.nav, className);

    return (
        <nav className={navClassName}>
            <ul className={styles.list}>
                <li>
                    <LinkActiveClassName href="/account" className={styles.link} activeClassName={styles.linkActive} title="Dashboard">
                        <Icon size='medium' icon="home-light" alt=" home icon" />
                        Dashboard
                    </LinkActiveClassName>
                </li>
                <li>
                    <LinkActiveClassName href="/account/orders" className={styles.link} activeClassName={styles.linkActive} title="Orders" partiallyActive>
                        <Icon size='medium' icon="basket-light" alt="basket Icon" />
                        Orders
                    </LinkActiveClassName>
                    <ul className={styles.submenu}>
                        <li>
                            {/* @ts-ignore */}
                            <LinkActiveClassName
                                href="/account/orders#order-history"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Order history"
                            >
                                View your order history
                            </LinkActiveClassName>
                        </li>
                        <li>
                            <LinkActiveClassName
                                href="/account/orders/treatment-reorders"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Treatment reorders"
                            >
                                Reorder a treatment
                            </LinkActiveClassName>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <LinkActiveClassName
                                href="/account/orders#saved-baskets"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Saved baskets"
                            >
                                Load a saved basket
                            </LinkActiveClassName>
                        </li>
                    </ul>
                </li>
                <li>
                    <LinkActiveClassName                    
                        href="/account/delivery-addresses"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Billing & delivery"
                        partiallyActive
                        matchesPath="/account/saved-payment-methods"
                    >
                        <Icon size='medium' icon="truck-light" alt="Truck Icon" />
                        Billing & delivery
                    </LinkActiveClassName>
                    <ul className={styles.submenu}>
                        <li>
                            <LinkActiveClassName
                                href="/account/delivery-addresses"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Delivery addresses"
                            >
                                Delivery addresses
                            </LinkActiveClassName>
                        </li>
                        <li>
                            <LinkActiveClassName
                                href="/account/saved-payment-methods"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Payment methods"
                            >
                                Payment methods
                            </LinkActiveClassName>
                        </li>
                    </ul>
                </li>
                <li>
                    <LinkActiveClassName
                        href="/account/personal-medical-record"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Personal Medical Record"
                        partiallyActive
                    >
                        <Icon size='medium' icon="clipboard-light" alt="clipboard icon" />
                        Medical Record
                    </LinkActiveClassName>
                    <ul className={styles.submenu}>
                        <li>
                            {/* @ts-ignore */}
                            <LinkActiveClassName
                                href="/account/personal-medical-record#personal-details"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Personal details"
                            >
                                Personal details
                            </LinkActiveClassName>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <LinkActiveClassName
                                href="/account/personal-medical-record#consultations"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Consultations"
                            >
                                Consultations
                            </LinkActiveClassName>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <LinkActiveClassName
                                href="/account/personal-medical-record#test-results"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Test results"
                            >
                                Test results
                            </LinkActiveClassName>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <LinkActiveClassName
                                href="/account/personal-medical-record#prescriptions"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Prescriptions"
                            >
                                Prescription Requests
                            </LinkActiveClassName>
                        </li>
                    </ul>
                </li>
                <li>
                    <LinkActiveClassName
                        href="/account/settings"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Settings"
                        partiallyActive
                    >
                        <Icon size='medium' icon="profile-light" alt="Profile Icon" />
                        Edit Details
                    </LinkActiveClassName>
                    <ul className={styles.submenu}>
                        <li>
                            <LinkActiveClassName
                                href="/account/settings"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Personal details"
                            >
                                Personal details
                            </LinkActiveClassName>
                        </li>
                        <li>
                            <LinkActiveClassName
                                href="/account/settings/weight-and-height"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Weight &amp; height"
                            >
                                Weight &amp; height
                            </LinkActiveClassName>
                        </li>
                        <li>
                            <LinkActiveClassName
                                href="/account/settings/password"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Update password"
                            >
                                Update password
                            </LinkActiveClassName>
                        </li>
                    </ul>
                </li>
                <li>
                    <Button variant="none" size="none" className={styles.link} title="Sign out of your account" onClick={handleLogout}>
                        <Icon size='medium' icon="exit-light" alt=" exit icon" />
                        Sign out
                    </Button>
                </li>
            </ul>
        </nav>
    );
};

export default AccountMenu;
