'use client';

import React from 'react';
import clx from 'classnames';
import { usePathname } from 'next/navigation';

import { HeaderFields } from '../../../Header/Header';

import Typography from '../../../../_ui/_blocks/Typography/Typography';
import ButtonLink from '../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import NavigationLink from '../../../NavigationLink/NavigationLink';

import styles from './Megamenu.module.css';

interface Props {
    navItems: NonNullable<HeaderFields['mainNavigation']>['items'];
}

const MegamenuTreatments = ({ navItems = [] }: Props) => {
    const pathName = usePathname();

    const classNames = clx(
        'p-200 space-y-200',
        styles.megamenu,
        // Hide the megamenu if the megamenu is on the homepage.
        pathName === '/' ? 'pointer-events-none invisible' : ''
    );

    // If the megamenu is being rendered on a GPPC page, remove it from the render for Google analytics
    // reasons.
    if ((pathName || '').includes('/gppc')) {
        return null;
    }

    return (
        <div className={classNames}>
            <div className="flex justify-between items-center">
                <Typography typeset="subtitle">Popular Treatments</Typography>
                <div className="flex gap-100">
                    <ButtonLink variant="primary" href="/treatments" title="View all treatments">
                        View all treatments
                    </ButtonLink>
                    <ButtonLink variant="secondary" href="/conditions" title="View all conditions">
                        View all conditions
                    </ButtonLink>
                </div>
            </div>
            <ul className="grid grid-cols-4 gap-200">
                {navItems?.map((navItem) => {
                    const title = `See all ${navItem.title} Treatments`;

                    return (
                        <li key={navItem._key} className="space-y-050">
                            <NavigationLink
                                title={title}
                                navItem={navItem}
                                className={`${styles.megamenuGroupHeader} type-heading leading-100`}
                                prefetch="hover"
                            >
                                <span>
                                    {navItem.title}{' '}
                                    <span className="text-content-quiet type-paragraph">
                                        ({navItem.reference.treatmentCountForCondition})
                                    </span>
                                </span>
                            </NavigationLink>
                            {navItem.children ? (
                                <ul className="space-y-050">
                                    {navItem.children.map((child) => (
                                        <Typography
                                            as="li"
                                            key={child._key}
                                            className="hover:text-actions-secondary"
                                            size="090"
                                        >
                                            <NavigationLink title={`View ${child.title}`} navItem={child} prefetch="hover">
                                                {child.title}
                                            </NavigationLink>
                                        </Typography>
                                    ))}
                                </ul>
                            ) : null}
                            <NavigationLink title={title} navItem={navItem} className="inline-block type-link">
                                View all
                            </NavigationLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default MegamenuTreatments;
