'use client';

import React from 'react';

import { usePathname } from 'next/navigation';
import { NavigationItemFields } from '../../../../../lib/sanity/queries/navigationItem.query';
import { getTypeOfMegamenuFromClasses } from '../Megamenu/Megamenu';

import Icon from '../../../../_ui/_blocks/Icon/Icon';

interface Props {
    classes: NavigationItemFields['classes'];
}

/**
 * Determines if the icon should be hidden based on the type of menu item and the current path.
 */
const shouldIconBeShown = (type: string, pathName: string) => {
    // If it's the brands menu item and we're on a GPPC page.
    if (type === 'brands' && pathName.includes('/gppc')) {
        return false;
    }

    // If it's the treatments menu item and we're on the homepage or GPPC page.
    if (type === 'treatments' && (pathName === '/' || pathName.includes('/gppc'))) {
        return false;
    }

    return true;
}

/**
 * Icons displayed next to each main menu item in the header.
 */
const DropDownIcon = ({ classes }: Props) => {
    const type = getTypeOfMegamenuFromClasses(classes);

    const pathName = usePathname() || '';

    return shouldIconBeShown(type, pathName) ? (
        <Icon icon="chevron-down-light" alt="" aria-hidden="true" loading="eager" className="-mb-[3px]" />
    ) : null;
};

export default DropDownIcon;
