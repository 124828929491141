'use client';

import React, { Fragment, useId } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import useOnNavigate from '../../../hooks/useOnNavigate';

import ModalTitle from '../Modal/ModalTitle/ModalTitle';
import Icon from '../_blocks/Icon/Icon';

import styles from './Flyout.module.css';

const initialPositioningClasses = {
    top: '',
    bottom: '',
    left: styles.flyoutLeft,
    right: styles.flyoutRight,
};

const transformClasses = {
    top: {
        enterFrom: '-translate-y-full',
        leaveTo: '-translate-y-full',
    },
    bottom: {
        enterFrom: 'translate-y-full',
        leaveTo: 'translate-y-full',
    },
    left: {
        enterFrom: '-translate-x-full',
        leaveTo: '-translate-x-full',
    },
    right: {
        enterFrom: 'translate-x-full',
        leaveTo: 'translate-x-full',
    },
};

interface Props {
    /** To determine whether the Flyout is open or not. */
    isOpen: boolean;
    /** Applies a title to the Flyout */
    title: string | React.ReactNode;
    /** Children that are rendered inside the Flyout. */
    children: React.ReactNode;
    /** Function to onclose the Flyout. */
    onClose?: () => void;
    /** Show or hide the overlay layer when flyout is open */
    disableOverlay?: boolean;
    openFromThe?: 'left' | 'right' | 'top' | 'bottom';
    onBack?: () => void;
    afterLeave?: () => void;
    closeIcon?: 'cross-light' | 'chevron-down-light';
    className?: string;
    theme?: "white" | "grey";
}

/**
 * This flys out content from the left, right, top or bottom of the screen.
 * This component is a doozy =/
 *
 * Basically, as the flyout is technically rendering off screen, if there is a focusable element in the
 * flyout, the browser cancels the enter animation. The exit animation is fine. To get around this issue, the
 * inert attribute (see link below) is added to the flyout element when it is first mounted.
 *
 * inert tells the browser to ignore the element and so there are no interactional element inside it as a
 * result. This allows the flyout to animate in correctly. Once the flyout has finished animating in, the
 * inert attribute is removed so it can be interacted with.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
 */
const Flyout = ({
    isOpen,
    openFromThe = 'right',
    disableOverlay,
    title,
    closeIcon = 'cross-light',
    className,
    children,
    onClose = () => {},
    onBack,
    afterLeave,
    theme = 'grey',
}: Props) => {
    // Store the inert state in a useRef to avoid re-renders. If the dialog content rerenders, it will use
    // this ref.
    const inertAttribute = React.useRef<Record<string, 'true'>>({ inert: 'true' });

    // Unique class to target the flyout element with. Can't use a ref as the Dialog.panel depends on a ref
    // it's using internally.
    const flyoutClass = `flyout-${useId()}`;

    // Runs when the flyout animation has finished. We remove the inert attribute from the flyout element at
    // this stage and update the inert ref to disable inert.
    const onAfterEnter = () => {
        inertAttribute.current = {};
        const elements = document.getElementsByClassName(flyoutClass);
        if (elements.length) {
            elements[0].removeAttribute('inert');
        }
    };

    // Runs before the flyout close animation starts to add inert back into the ref.
    const onBeforeLeave = () => {
        inertAttribute.current = { inert: 'true' };
    };

    useOnNavigate(onClose, { trackHash: true });

    return (
        <Transition show={isOpen} as={Dialog} className={`${styles.dialog} ${className}`} onClose={onClose} afterLeave={afterLeave}>
            {!disableOverlay ? (
                <Transition.Child
                    enter="transition-opacity ease-easeOut duration-fast"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-easeIn duration-fast"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={styles.overlay} />
                </Transition.Child>
            ) : null}
            <Transition.Child
                as={Fragment}
                {...transformClasses[openFromThe]}
                beforeLeave={onBeforeLeave}
                afterEnter={onAfterEnter}
            >
                <Dialog.Panel
                    as="aside"
                    className={`${styles.flyout} ${initialPositioningClasses[openFromThe]} ${flyoutClass}`}
                    {...inertAttribute.current}
                >
                    <header className={styles.header}>
                        {onBack ? (
                            <button
                                type="button"
                                title="Click to go back"
                                className={styles.backButton}
                                onClick={onBack}
                            >
                                <Icon icon="chevron-left-light" alt="Back" />
                            </button>
                        ) : null}
                        <ModalTitle
                            as="h3"
                            typeset="heading"
                            size="090"
                            lineHeight="1000"
                            className="flex-grow"
                        >
                            {title}
                        </ModalTitle>
                        <button type="button" title="Close" onClick={onClose} className={styles.close}>
                            <Icon icon={closeIcon} alt="Close" />
                        </button>
                    </header>
                    <div className={`${styles.inner} ${theme === "white" ? '' : 'bg-layers-canvas'}`}>{children}</div>
                </Dialog.Panel>
            </Transition.Child>
        </Transition>
    );
};

export default Flyout;
