'use client';

import React from 'react';
import { usePathname } from 'next/navigation';

import { HeaderFields } from '../../../Header/Header';

import ButtonLink from '../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Typography from '../../../../_ui/_blocks/Typography/Typography';
import NavigationLink from '../../../NavigationLink/NavigationLink';
import Link from '../../../../NextJs/Link/Link';

import styles from './Megamenu.module.css';

interface Props {
    navItems: NonNullable<HeaderFields['mainNavigation']>['items'];
    treatmentCount: number;
    conditionCount: number;
}

/**
 * Megamenu to display all the brand links.
 */
const MegamenuBrands = ({ navItems, treatmentCount, conditionCount }: Props) => {
    const pathName = usePathname();

    // If the megamenu is being rendered on a GPPC page, remove it from the render for Google analytics
    // reasons.
    if ((pathName || '').includes('/gppc')) {
        return null;
    }

    return (
        <div className={`flex ${styles.megamenu}`}>
            <div className="lg:min-w-[30rem] p-200">
                <div className="flex justify-between items-center type-subtitle leading-200">
                    Popular Brands
                    <Link
                        href="/brands"
                        title="View all brands"
                        className="p-050 -m-050 type-paragraph type-link-reverse"
                    >
                        View all
                    </Link>
                </div>
                <ul className="grid grid-cols-2 gap-150 mt-200">
                    {navItems.map((navItem) => (
                        <li key={navItem._key}>
                            <NavigationLink
                                title={`See all ${navItem.title} treatments`}
                                navItem={navItem}
                                className="type-100"
                            >
                                {navItem.title}
                            </NavigationLink>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.conditionOffers}>
                <Typography as="div" typeset="heading" size="100">
                    We offer {treatmentCount} over-the-counter and prescription
                    <br /> medicines, for the treatment of {conditionCount} conditions.
                </Typography>
                <Typography as="div" color="quiet" size="100" className="mt-050">
                    We also provide expert advice on a range of common
                    <br /> health issues.
                </Typography>
                <ButtonLink
                    variant="primary"
                    href="/treatments"
                    title="Find your treatment"
                    className="mt-100 text-center mx-auto"
                >
                    Find your treatment
                </ButtonLink>
            </div>
        </div>
    );
};

export default MegamenuBrands;
