'use client';

import React from 'react';
import dynamic from 'next/dynamic';

import { useAuthContext } from '../../../data/context/authContext';

import LoggedOutNav from './Navigation/LoggedOutNav';

import styles from './AccountDropdown.module.css';

const LoggedInNav = dynamic(() => import('./Navigation/LoggedInNav'), {
    ssr: false,
    loading: LoggedOutNav,
});

const AccountDropdown = () => {
    const {isLoggedIn} = useAuthContext();

    return (
        <div className={`hidden lg:flex lg:items-center ${styles.listItem}`}>
            {isLoggedIn ? <LoggedInNav /> : <LoggedOutNav />}
        </div>
    );
};

export default AccountDropdown;
