import { useRef, useEffect } from 'react';

/**
 * Because there is no page refresh, we need to close the mega menu on click.
 */
const useCloseMegaMenuOnClick = (onClick: (() => void) | undefined = undefined) => {
    const timeout = useRef<NodeJS.Timeout | undefined>();

    const handleClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, 500);
    };

    // On unmount, if there is still a set timeout, clear it and remove added body class.
    useEffect(() => (
        () => {
            // On unmount clear timeout.
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        }
    ), []);

    return handleClick;
};

export default useCloseMegaMenuOnClick;
