'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import clx from 'classnames';

import Link from './NextJs/Link/Link';

// Props interface extends from our custom NextJS link props.
interface LinkActiveClassNameProps extends React.ComponentProps<typeof Link> {
    activeClassName?: string;
    partiallyActive?: boolean;
    matchesPath?: string;
}

const LinkActiveClassName = ({ partiallyActive, matchesPath, activeClassName, ...props}: LinkActiveClassNameProps) => {
    const currentPath = usePathname();
    const href = (props.href as string);

    const classNames = clx(props.className,
        currentPath === href ? activeClassName: null,
        partiallyActive && currentPath?.includes(href) ? activeClassName : null,
        matchesPath && currentPath?.includes(matchesPath) ? activeClassName : null
    );

    return (<Link {...props} className={classNames}/>);
};

export default LinkActiveClassName;
