'use client';

import React, { Fragment, useCallback } from 'react';
import clx from 'classnames';

import { useAuthContext } from '../../../../data/context/authContext';
import { useSidebarNavigationValueContext, useSidebarNavigationUpdaterContext } from '../../../../data/context/sidebarNavigationContext';
import { useAccountNavigationContext } from '../../../../data/context/accountContext/accountNavigationContext';
import { HeaderFields } from '../../Header/Header';
import useSidebarScrollToHash from './hooks/useSidebarScrollToHash';

import Flyout from '../../../_ui/Flyout/Flyout';
import ButtonLink from '../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import AccountNavigation from '../../AccountNavigation';
import LinkActiveClassName from '../../../LinkActiveClassName';
import NavigationLink from '../../NavigationLink/NavigationLink';

import styles from './SidebarNavigation.module.css';
import Icon from '../../../_ui/_blocks/Icon/Icon';
import { icons } from '../../../_ui/_blocks/Icon/icon.config';

interface Props {
    navItems: NonNullable<HeaderFields['sidebarNavigation']>['items'];
}

/**
 * Mobile navigation
 */
const SidebarNavigation = ({ navItems }: Props) => {
    const { isLoggedIn, logout } = useAuthContext();

    const sidebarNavIsOpen = useSidebarNavigationValueContext();
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();
    const { setAccountNavIsOpen } = useAccountNavigationContext();

    const handleLogout = () => logout();

    const closeNavigation = useCallback(() => {
        setSidebarNavIsOpen(false);
    }, [setSidebarNavIsOpen]);

    const handleAccountClick = () => {
        closeNavigation();
        setAccountNavIsOpen(true);
    };

    const navClassName = clx(styles.nav, {
        [styles.navBottomPadding]: !isLoggedIn,
    });

    const scrollToHashOnClose = useSidebarScrollToHash(sidebarNavIsOpen);

    return (
        <Fragment>
            <Flyout
                isOpen={sidebarNavIsOpen}
                title="Menu"
                onClose={closeNavigation}
                afterLeave={scrollToHashOnClose}
                theme="white"
            >
                <nav className={navClassName}>
                    <ul className={styles.list}>
                        <li>
                            <LinkActiveClassName href="/" className={styles.link} title="Home" activeClassName={styles.linkActive}>
                                <Icon icon="home-light" alt="home icon" />
                                Home
                            </LinkActiveClassName>
                        </li>
                        {isLoggedIn ? (
                            <li>
                                <button type="button" className={styles.link} onClick={handleAccountClick}>
                                    <Icon icon="profile-light" alt="profile icon" />
                                    Your account
                                    <Icon icon="chevron-right-light" size="xsmall" className="ml-auto" alt='chevron right' />
                                </button>
                            </li>
                        ) : null}
                        {(navItems || []).map((navItem) => (
                            <li key={navItem._key}>
                                <NavigationLink
                                    className={styles.link}
                                    title={navItem.title}
                                    navItem={navItem}
                                    activeClassName={styles.linkActive}
                                >
                                    {navItem.icon ? (
                                        <Icon icon={navItem.icon as keyof typeof icons} alt={navItem.icon} />
                                    ) : null}
                                    {navItem.title}
                                </NavigationLink>
                                {navItem.children?.length ? (
                                    <ul className={styles.submenu}>
                                        {navItem.children.map((child) => (
                                            <li key={child._key}>
                                                <NavigationLink
                                                    title={child.title}
                                                    navItem={child}
                                                    className={styles.sublink}
                                                    activeClassName={styles.sublinkActive}
                                                >
                                                    {child.title}
                                                </NavigationLink>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        ))}
                        {isLoggedIn ? (
                            <li>
                                <Button
                                    variant="none"
                                    size="none"
                                    className={styles.link}
                                    title="Sign out of your account"
                                    onClick={handleLogout}
                                >
                                    <Icon icon="exit-light" alt="exit Icon" />
                                    Sign out
                                </Button>
                            </li>
                        ) : (
                            <li className="!border-t-0 !p-0">
                                <ButtonLink className={styles.signIn} href="/login" title="Signin to your account">
                                    Sign In
                                </ButtonLink>
                            </li>
                        )}
                    </ul>
                </nav>
            </Flyout>
            {isLoggedIn ? <AccountNavigation /> : null}
        </Fragment>
    );
};

export default SidebarNavigation;
