import { useRef, useEffect } from 'react';
import { selectorScrollTo } from '../../../../../helpers/scrollTo';

// Gets the current URL hash.
const getHash = () => typeof window !== 'undefined' ? window.location.hash : '';

/**
 * Tracks the hash in the URL when the sidebar opens or closes. If the hash changes between open and close, then the returned function
 */
const useSidebarScrollToHash = (isOpen: boolean) => {
    const hashOnOpen = useRef('');
    const hashToScrollTo = useRef('');

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        if (isOpen) {
            hashOnOpen.current = getHash();
            return;
        }

        const newHash = getHash();

        // If there is a valid hash and it has changed since the sidebar was opened.
        if (newHash && hashOnOpen.current !== newHash) {
            hashOnOpen.current = newHash;
            hashToScrollTo.current = newHash;
        }
    }, [isOpen]);

    return () => {
        if (!hashToScrollTo.current) {
            return;
        }

        selectorScrollTo(hashToScrollTo.current);
        hashToScrollTo.current = '';
    }
};

export default useSidebarScrollToHash;
